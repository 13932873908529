import React, { FC } from 'react';
import { graphql } from 'gatsby';

import InfoComponent from 'layout/InfoComponent';

import { InfoPageProps } from './models';

const InfoPage: FC<InfoPageProps> = ({ data: { infoPage } }) => <InfoComponent info={infoPage} />;

export const query = graphql`
  query ($url: String) {
    infoPage(url: { eq: $url }) {
      title
      subTitle
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      image {
        childImageSharp {
          gatsbyImageData(width: 150, height: 150)
        }
      }
      body
      url
      button {
        buttonText
        buttonLink {
          name
          url
        }
      }
      bibliographyLabel
      bibliography {
        reference
      }
      relatedArticleLabel
      relatedProductExtraInfo
      relatedProduct {
        basicInfo {
          image {
            childImageSharp {
              gatsbyImageData(width: 300, height: 300)
            }
          }
        }
      }
      relatedArticle {
        url
        basicInfo {
          title
          banner {
            image {
              childImageSharp {
                gatsbyImageData(width: 300, height: 200)
              }
            }
          }
        }
      }
    }
  }
`;

export default InfoPage;
