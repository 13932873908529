import React, { FC } from 'react';

import { ReferenceProps } from './models';

const Reference: FC<ReferenceProps> = ({ reference: { reference } }) => {
  if (reference === null) return null;

  return <div className="bibliography__body" dangerouslySetInnerHTML={{ __html: reference }} />;
};

export default Reference;
