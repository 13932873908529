import React, { FC } from 'react';
import { Link } from 'gatsby';

import { Layout } from 'layout';
import ArticlesNodes from 'layout/ArticlesNodes';
import BannerTempraNinos from 'layout/BannerTempraNinos';
import MainSlider from 'layout/MainSlider';
import Reference from 'layout/Reference';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { InfoProps } from './models';

import './infoComponent.scss';

const InfoComponent: FC<InfoProps> = ({
  info: {
    bibliography,
    bibliographyLabel,
    body,
    button,
    image,
    banner,
    mainslider,
    title,
    subTitle,
    relatedArticle,
    relatedProductExtraInfo,
    relatedArticleLabel,
    relatedProduct,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
  },
}) => {
  const renderArticle = relatedArticle
    ? relatedArticle.map((article) => {
        const { url } = article;

        return (
          <div className="gid__column--4 col-6 col-md-4 content__article" key={url}>
            <ArticlesNodes article={article} />
          </div>
        );
      })
    : null;
  const nameProduct = relatedProductExtraInfo || '';
  const relatedImage = relatedProduct ? relatedProduct[0].basicInfo[0].image : null;

  return (
    <Layout>
      <Seo
        {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <div className="content container-fluid result">
        <div className="content__row row">
          <div className="content__column--12 col-12 px-0">
            {banner ? <BannerTempraNinos bannerTempra={banner} /> : null}
            {mainslider ? <MainSlider slider={mainslider} /> : null}
          </div>
          <div className="content__column--12 col-12 text-center">
            <div className="content container">
              <div className="content__title">
                <h1 className="title">{title}</h1>
              </div>
              {image ? (
                <div className="image__content">
                  <Image imageData={image} alt={title} />
                </div>
              ) : null}
              <h3>{subTitle}</h3>
              <div className="description__content" dangerouslySetInnerHTML={{ __html: body }} />
              <div className="btn__content">
                {button.map(({ buttonText, buttonLink }) => {
                  const { url: urlButton } = buttonLink[0];

                  return (
                    <Link className="btn__link btn" to={urlButton} key={buttonText}>
                      {buttonText}
                    </Link>
                  );
                })}
              </div>
              {bibliography ? (
                <div className="bibliography__content">
                  <div className="bibliography__title">
                    <h3 className="title">{bibliographyLabel}</h3>
                  </div>
                  <Reference reference={bibliography} />
                </div>
              ) : null}
            </div>
            {relatedImage ? <Image imageData={relatedImage} alt={title} /> : null}
            <div
              className="description__content"
              dangerouslySetInnerHTML={{ __html: nameProduct }}
            />
            <h3 className="articles__title">{relatedArticleLabel}</h3>
            <div className="row">{renderArticle}</div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default InfoComponent;
