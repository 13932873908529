import React, { FC } from 'react';
import { Link } from 'gatsby';
import Carousel from 'nuka-carousel';

import Image from 'common/Image';

import { MainSliderProps } from './models';

import './carousel.scss';

const MainSlider: FC<MainSliderProps> = ({ slider: { banner } }) => {
  const renderSlider = banner.map((sliderComponent) => {
    const { title } = sliderComponent.properties ? sliderComponent.properties : sliderComponent;
    const { image, link } = sliderComponent.properties
      ? sliderComponent.properties.banner[0].properties
      : sliderComponent.banner[0];
    const { url } = link[0];

    return (
      <div className="slider__item" key={title}>
        <Link to={url}>
          <h2 className="slider__item--title">{title}</h2>
          <Image imageData={image} alt={title} />
        </Link>
      </div>
    );
  });

  return <Carousel>{renderSlider}</Carousel>;
};

export default MainSlider;
